<template>
  <v-main>
    <Header
      :title="'Incidência para o IRRF'"
      :subtitle="'Gerenciamento das atualizações dos leiautes do e-Social'"
      :itemsBreadCrumb="itemsBreadCrumb"
      :hasUpdateInfo="false"
      :hasAddButton="true"
      :addButtonText="'Adicionar incidência'"
      @clickAdd="openModal"
    />
    <div id="tables-container">
      <v-card class="pl-0 pr-0 pt-0 pb-0">
        <v-card-title>
          <TableSearch
            v-model="search"
            label="Pesquisar por código ou nome"
            id="incidenceIRRFSearchBar"
            @input="onInputSearchBar"
          >
          </TableSearch>
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12" sm="12" class="text-right">
              <span
                id="attachId"
                style="
                  max-width: 384px !important;
                  min-width: 384px !important;
                  max-height: 600px !important;
                  min-height: 600px !important;
                "
              >
                <v-menu
                  offset-y
                  left
                  :close-on-content-click="false"
                  v-model="openMenu"
                  :close-on-click="closeOnClick"
                  attach="#attachId"
                  style="
                    max-width: 384px !important;
                    min-width: 384px !important;
                    max-height: 600px !important;
                    min-height: 600px !important;
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="btn-ag-secondary" icon v-bind="attrs" v-on="on" @click="clickButtonFilter()">
                      <v-icon class="material-icons-outlined">filter_alt</v-icon>Filtro
                      {{ totalSelectedFilters > 0 && !openMenu ? `(${totalSelectedFilters})` : '' }}
                    </v-btn>
                  </template>
                  <v-card
                    style="
                      max-width: 384px !important;
                      min-width: 384px !important;
                      max-height: 600px !important;
                      min-height: 600px !important;
                    "
                    class="mb-2 pa-0"
                  >
                    <v-card-title class="pa-4" style="max-height: 64px !important">
                      <span
                        class="text-h5"
                        :style="'color: #182026;font-weight:700;line-height:30px!important;font-size:20px!important;height:30px!important'"
                        >Filtro</span
                      >
                      <v-spacer></v-spacer>
                      <v-btn icon dark @click="closeFromX" class="pa-0 ma-0">
                        <v-icon class="mr-0">mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-list
                      class="incidence_irrf_filters ma-0 pa-0"
                      width="384"
                      style="
                        max-height: 480px !important;
                        min-height: 480px !important;
                        border-top: 1px solid var(--v-secondary-lighten5) !important;
                      "
                    >
                      <v-list-item class="pb-0 mb-4">
                        <v-combobox
                          class="combobox_incidence_irrf_filter"
                          v-model="filter.group"
                          :items="groups"
                          label="Grupo"
                          hide-details
                          outlined
                          dense
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content class="combobox_incidence_irrf_filter_item_list">
                              <v-list-item-title>
                                {{ item }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-combobox>
                      </v-list-item>
                      <!-- Situação -->
                      <v-list-item class="pb-0 mb-4">
                        <v-combobox
                          class="combobox_incidence_irrf_filter"
                          v-model="filter.situation"
                          :items="situations"
                          label="Situação"
                          hide-details
                          outlined
                          dense
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content class="combobox_incidence_irrf_filter_item_list">
                              <v-list-item-title>
                                {{ item }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-combobox>
                      </v-list-item>
                      <!-- Período de início -->

                      <v-list-item class="pb-0 mb-4">
                        <AGDatePickerRangeMonth
                          style="min-width: 352px !important"
                          id="datepickerPeriodStart"
                          label="Período de início"
                          v-model="filter.start"
                        />
                      </v-list-item>
                      <!-- Período de término -->
                      <v-list-item class="pb-0 mb-4">
                        <AGDatePickerRangeMonth
                          style="min-width: 352px !important"
                          id="datepickerPeriodEnd"
                          label="Período de término"
                          v-model="filter.end"
                        />
                      </v-list-item>
                      <!-- Período de Atualização -->
                      <v-list-item class="pb-0 mb-4">
                        <AGDatePickerRange
                          style="min-width: 352px !important"
                          id="datepickerPeriod"
                          label="Período de atualização"
                          :allowedDates="funcForDates"
                          v-model="filter.updatedAt"
                        />
                      </v-list-item>
                      <v-list-item class="pb-0 mb-4">
                        <v-combobox
                          class="combobox_incidence_irrf_filter"
                          v-model="filter.updateBy"
                          :items="usersComboboxItems"
                          label="Atualizado por"
                          hide-details
                          outlined
                          dense
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content class="combobox_incidence_irrf_filter_item_list">
                              <v-list-item-title>
                                {{ item }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-combobox>
                      </v-list-item>
                      <!-- Aplicar -->
                      <v-list-item class="pa-4">
                        <v-btn
                          :disabled="habilitarBotaoFiltro"
                          @click="callFilter"
                          :style="
                            habilitarBotaoFiltro
                              ? 'width: 352px; max-height: 48px !important; min-height: 48px !important; background-color: #e7e7fa !important;color:#8686A2!important'
                              : 'width: 352px; max-height: 48px !important; min-height: 48px !important; background-color: var(--v-primary-base) !important;color:white!important'
                          "
                        >
                          Aplicar
                        </v-btn>
                      </v-list-item>
                      <!-- Limpar filtro -->
                      <v-list-item class="pb-4">
                        <v-btn
                          @click="limparFiltro"
                          :disabled="habilitarBotaoLimparFiltro"
                          :class="habilitarBotaoLimparFiltro ? 'clear-filter-disabled' : 'clear-filter-enabled'"
                        >
                          <span
                            class="material-symbols-outlined"
                            style="max-width: 1px; position: fixed; margin-left: -150px"
                          >
                            delete
                          </span>
                          Limpar filtro</v-btn
                        >
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </span>
              <template>
                <v-btn class="btn-ag-tertiary ml-2" @click="exportar()" style="width: 127px" icon id="exportBtn">
                  <v-icon>mdi-download-outline</v-icon> Exportar
                </v-btn>
              </template>
            </v-col>
          </v-row>
        </v-card-title>
        <!-- Chips  -->

        <v-row>
          <v-col cols="12" sm="12">
            <template>
              <div class="text-right">
                <v-chip v-if="showChipSituation && !openMenu" class="ma-1" close @click:close="removeSituation()">
                  {{ capitalize(filter.situation) }}
                </v-chip>
                <v-chip
                  v-if="showChipPeriodStart && !openMenu"
                  class="ma-1"
                  close
                  @click:close="removeChipPeriodStart()"
                >
                  {{ concatDate(filter.start, 'Início: ') }}
                </v-chip>
                <v-chip v-if="showChipPeriodEnd && !openMenu" class="ma-1" close @click:close="removeChipPeriodEnd()">
                  {{ concatDate(filter.end, 'Témino: ') }}
                </v-chip>
                <v-chip v-if="showChipUpdatedAt && !openMenu" class="ma-1" close @click:close="removeChipUpdatedAt()">
                  {{ concatDate(filter.updatedAt, 'Atualização: ') }}
                </v-chip>
                <v-chip v-if="showChipUpdateBy && !openMenu" class="ma-1" close @click:close="removeUpdateBy()">
                  {{ capitalize(`${filter.updateBy}`) }}
                </v-chip>
              </div>
            </template>
          </v-col>
        </v-row>

        <div id="scrollDuplicate" class="table_scroll_duplicate">
          <div id="dummy"></div>
        </div>
        <v-data-table
          :headers="headers"
          :items="incidences"
          class="elevation-1"
          :loading="loading"
          :options.sync="pagination"
          :no-data-text="noDataText"
          :page="pagination.page"
          :server-items-length="totalIncidences"
          :footer-props="{
            'items-per-page-options': [50, 100, 250],
            itemsPerPageText: `Incidência por página: `,
            disableItemsPerPage: incidences.length < 50,
          }"
          @current-items="onTableInput"
        >
          <template v-slot:footer.page-text="items">
            {{ items.pageStart }} - {{ items.pageStop }} de {{ totalIncidences }} incidências
          </template>
          <template v-slot:item.name="{ item }">
            <AGTooltip v-if="checkLength(item.name)" :tooltipText="item.name">
              <template slot:tooltipArea>
                <span class="text-no-wrap">
                  {{ truncateText(item.name) }}
                </span>
              </template>
            </AGTooltip>
            <div v-else>
              <span class="text-no-wrap">
                {{ item.name }}
              </span>
            </div>
          </template>
          <template v-slot:item.group="{ item }">
            <AGTooltip v-if="checkLength(item.group, 30)" :tooltipText="item.group">
              <template slot:tooltipArea>
                <span class="text-no-wrap">
                  {{ truncateText(item.group, 30) }}
                </span>
              </template>
            </AGTooltip>
            <div v-else>
              <span class="text-no-wrap">
                {{ item.group }}
              </span>
            </div>
          </template>
          <template v-slot:item.user="{ item }">
            <AGTooltip v-if="checkLength(item.user)" :tooltipText="item.user">
              <template slot:tooltipArea>
                <span class="text-no-wrap">
                  {{ truncateText(item.user) }}
                </span>
              </template>
            </AGTooltip>
            <div v-else>
              <span class="text-no-wrap">
                {{ item.user }}
              </span>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" id="idEdit" @click="openModalEdit(item)">
                  <v-icon small color="success"> mdi-pencil-outline </v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" id="idHistoric" @click="openModalHistoric(item)">
                  <v-icon small class="mr-2"> mdi-history</v-icon>
                </v-btn>
              </template>
              <span>Histórico</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <ModalIncidenceIRRFNew ref="modalIncidenceIRRFNew" @refresh="Refreshfetch" :incidences="incidences" />
    <ModalIncidenceIRRFEdit ref="modalIncidenceIRRFEdit" @refresh="Refreshfetch" :incidence="incidence" />
    <ModalIncidenceIRRFHistoric ref="modalIncidenceIRRFHistoric" :history="arrHistoric" />
    <ModalIncidenceIRRFHistoricUpdate ref="modalIncidenceIRRFHistoricUpdate" :history="arrHistoric" />
  </v-main>
</template>
<script>
import eSocialService from '@/services/eSocialService.js';
import cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapState, mapGetters } from 'vuex';
import { eventBus } from '../../../../main.js';
import { definePaginationQuery, isSamePagination } from '@/utils/pagination';
import { truncateText, checkLength } from '@/utils/text.js';

export default {
  name: 'IncidenceIRRF',
  components: {
    ModalIncidenceIRRFEdit: () => import('./components/ModalIncidenceIRRFEdit.vue'),
    ModalIncidenceIRRFNew: () => import('./components/ModalIncidenceIRRFNew.vue'),
    ModalIncidenceIRRFHistoric: () => import('./components/ModalIncidenceIRRFHistoric.vue'),
    ModalIncidenceIRRFHistoricUpdate: () => import('./components/ModalIncidenceIRRFHistoricUpdate.vue'),
    AGDatePickerRangeMonth: () => import('../../commons/AGDatePickerRangeMonth.vue'),
    AGDatePickerRange: () => import('../../commons/AGDatePickerRange.vue'),
    AGTooltip: () => import('../../commons/AGTooltip.vue'),
    TableSearch: () => import('../../commons/TableSearch.vue'),
    Header: () => import('../../commons/Header.vue'),
  },
  created() {
    this.getUsers();
    this.fetch();
  },
  mounted() {
    this.updateScrollers();
    this.setScrollersEventListener();
  },
  data() {
    return {
      incidences: [],
      incidence: {},
      totalIncidences: 0,
      loading: false,
      situations: ['Ativo', 'Inativo'],
      groups: [
        'Sem grupo',
        'Rendimento tributável (base de cálculo do IR)  ',
        'Retenção do IRRF efetuada sobre',
        'Dedução do rendimento tributável do IRRF',
        'Rendimento não tributável ou isento do IRRF',
        'Demandas judiciais',
        'Exigibilidade suspensa - Rendimento tributável (base de cálculo do IR)',
        'Exigibilidade suspensa - Retenção do IRRF efetuada sobre',
        'Exigibilidade suspensa - Dedução da base de cálculo do IRRF',
        'Compensação judicial',
      ],
      updatesBy: [],
      filter: {
        group: '',
        situation: '',
        start: [],
        end: [],
        updatedAt: [],
        updateBy: '',
      },
      noDataText: 'Nenhuma incidência encontrada',
      dateMessageError: '',
      dateMessageValidadeError: '',
      partialFilter: {},
      params: {
        group: null,
        situation: null,
        start: null,
        end: null,
        updatedAt: null,
        user: null,
      },
      search: '',
      closeOnClick: false,
      openMenu: false,
      showChipSituation: false,
      showChipPeriodStart: false,
      showChipPeriodEnd: false,
      showChipUpdatedAt: false,
      showChipUpdateBy: false,
      applyClicked: false,
      totalSelectedFilters: 0,
      headers: [
        { text: 'Código', align: 'start', value: 'code', width: '78px', sortable: false },
        { text: 'Nome', align: 'start', value: 'name', width: 'auto', sortable: false },
        { text: 'Grupo', align: 'start', value: 'group', width: 'auto', sortable: false },
        { text: 'Situação', align: 'start', value: 'situation', width: '89px', sortable: false },
        { text: 'Início', align: 'start', value: 'start', width: '94px', sortable: false },
        { text: 'Término', align: 'start', value: 'end', width: '94px', sortable: false },
        { text: 'Atualização', value: 'updatedAt', width: '188px', sortable: false },
        { text: 'Atualizado por', value: 'user', width: 'auto', sortable: false },
        { text: 'Ações', align: 'center', value: 'actions', width: '144px', sortable: false },
      ],
      options: {
        itemsPerPage: 50,
        page: 1,
        sortBy: [],
      },
      arrHistoric: [],
      usuarios: [],
    };
  },
  computed: {
    ...mapState('usuarios', {
      users: (state) => state.users,
    }),
    ...mapGetters('cadastrosGerenciais/tabelasLeiautesModule', {
      incidenceIrrfPagination: 'getIncidenceIrrfPagination',
    }),
    itemsBreadCrumb() {
      let itemsBreadCrumb = [
        {
          title: 'Home',
          disabled: false,
          href: 'home',
        },
        {
          title: 'Configurações',
          disabled: true,
        },
        {
          title: 'e-Social',
          disabled: true,
        },
        {
          title: 'Tabelas',
          disabled: false,
          href: '/cadastros-gerenciais/e-social/tables',
        },
        {
          title: 'Incidência para o IRRF',
          disabled: true,
          active: true,
        },
      ];
      return itemsBreadCrumb;
    },
    pagination: {
      get: function () {
        if ([undefined, null].includes(this.incidenceIrrfPagination)) {
          return this.options;
        }
        return this.incidenceIrrfPagination;
      },
      set: function (value) {
        let samePagination = isSamePagination(this.pagination, value);
        this.setIncidenceIrrfPagination(value);
        if (!samePagination) {
          this.fetch();
        }
      },
    },
    habilitarBotaoLimparFiltro: function () {
      if (this.filter.group) {
        return false;
      } else if (this.filter.situation) {
        return false;
      } else if (this.filter.updateBy) {
        return false;
      } else if (this.filter.start.length == 1 || this.filter.start.length == 2) {
        return false;
      } else if (this.filter.end.length == 1 || this.filter.end.length == 2) {
        return false;
      } else if (this.filter.updatedAt.length == 1 || this.filter.updatedAt.length == 2) {
        return false;
      }

      return true;
    },
    habilitarBotaoFiltro: function () {
      if (this.dateMessageError || this.dateMessageValidadeError) {
        return true;
      }
      if (this.filter.group) {
        return false;
      }
      if (this.filter.situation) {
        return false;
      }
      if (this.filter.start.length == 2) {
        return false;
      }
      if (this.filter.end.length == 2) {
        return false;
      }
      if (this.filter.updatedAt.length == 2 || this.filter.updatedAt.length == 1) {
        return false;
      }
      if (this.filter.updateBy) {
        return false;
      }
      return true;
    },
    usersComboboxItems() {
      let comboUsers = [];
      this.users.forEach((user) => {
        comboUsers.push(user.firstName + ' ' + user.lastName);
      });
      return comboUsers;
    },
  },
  methods: {
    truncateText,
    checkLength,
    ...mapActions('usuarios', ['getUsers', 'saveUser']),
    ...mapActions('cadastrosGerenciais/tabelasLeiautesModule', ['setIncidenceIrrfPagination']),
    openModal() {
      this.incidence = {};
      this.$refs.modalIncidenceIRRFNew.$emit('open');
    },
    funcForDates(dt) {
      return dt <= new Date().toISOString().substring(0, 10);
    },
    openModalEdit(item) {
      this.incidence = {};
      this.incidence = item;
      this.incidence.notes = '';
      setTimeout(() => {
        this.$refs.modalIncidenceIRRFEdit.$emit('open');
      }, 500);
    },
    openModalHistoric(item) {
      this.getHistory(item.code);
    },
    openModalHistoricUpdate() {
      this.$refs.modalIncidenceIRRFHistoricUpdate.$emit('open');
    },
    Refreshfetch() {
      this.incidence = {};
      this.incidences = [];
      this.fetch();
    },
    async fetch() {
      // this.params.situation = this.filter.situation;
      // this.params.start = this.formatDateMMYYYY(this.filter.start[0]);
      // this.params.end = this.formatDateMMYYYY(this.filter.start[1]);
      // this.incidences = [];
      this.loading = true;
      this.params.codeName = this.search;
      if (this.applyClicked) {
        this.params.group = this.filter.group;
        this.params.situation = this.filter.situation == '' ? null : this.filter.situation;
        this.params.start = this.filter.start[0]
          ? [this.toTimestampMonth(this.filter.start[0]), this.toTimestampMonth(this.filter.start[1])]
          : null;
        this.params.end = this.filter.end[0]
          ? [this.toTimestampMonth(this.filter.end[0]), this.toTimestampMonth(this.filter.end[1])]
          : null;

        if (this.filter.updatedAt.length == 1) {
          const dataInicial = new Date(this.filter.updatedAt);
          const dataFinal = new Date(this.filter.updatedAt);
          dataInicial.setHours(0, 0, 0, 0);
          dataFinal.setHours(23, 59, 59, 999);
          dataFinal.setDate(dataFinal.getDate() + 1);
          this.params.updatedAt = [dataInicial.getTime(), dataFinal.getTime() - 1];
        } else {
          this.params.updatedAt = this.filter.updatedAt[0]
            ? [this.toTimestamp(this.filter.updatedAt[0]), this.toTimestamp(this.filter.updatedAt[1])]
            : null;
        }
        this.params.user = this.filter.updateBy ? this.filter.updateBy : null;
      }

      let pagination = definePaginationQuery(this.pagination);
      let requestData = this.paramsToRequest(this.params);

      try {
        const response = await eSocialService.rubricAutomationConfig.incidenceIRRFList(requestData, pagination);
        this.incidences = response.data.content;
        this.totalIncidences = response.data.totalElements;
        this.showChips();
      } catch (e) {
        console.error('Error at request /rubric-automation/config/irrf-incidence: ', e);
      }

      this.loading = false;
    },

    async getHistory(code) {
      this.arrHistoric = [];
      const response = await eSocialService.rubricAutomationConfig.history('irrf_incidence', { code: code });
      if (response) {
        response.data.forEach((element) => {
          let historic = {
            date: element.date,
            user: element.user,
            differences: {
              after: {
                code: '',
                desc: element.differences ? element.differences.after.desc : '',
                name: element.differences ? element.differences.after.name : '',
                situation: element.differences ? element.differences.after.situation : '',
                start: element.differences ? element.differences.after.start : '',
                user: element.differences ? element.differences.after.user : '',
              },
              before: {
                code: '',
                desc: element.differences ? element.differences.before.desc : '',
                name: element.differences ? element.differences.before.name : '',
                situation: element.differences ? element.differences.before.situation : '',
                start: element.differences ? element.differences.before.start : '',
                user: element.differences ? element.differences.before.user : '',
              },
            },
            notes: element.notes ? element.notes : '',
          };
          this.arrHistoric.push(historic);
          this.$refs.modalIncidenceIRRFHistoric.$emit('open');
        });
      }
    },
    paramsToRequest(params) {
      let paramsToRequest = Object.entries(params).reduce((mappedParams, [chave, valor]) => {
        if (![undefined, null, ''].includes(valor)) {
          if (chave === 'codeName') {
            valor += '$lk$$nml$';
          }
          if (chave == 'group') {
            if ((valor = 'Sem grupo')) {
              valor = '';
            }
          }
          mappedParams[chave] = valor;
        }
        return mappedParams;
      }, {});
      return paramsToRequest;
    },
    toTimestamp(date) {
      if (!date) return null;
      const [year, month, day] = date.toString().split('-');
      var data = new Date(year, month - 1, day);
      var timestamp = data.getTime();
      return timestamp;
    },
    toTimestampMonth(date) {
      if (!date) return null;
      const [year, month, day] = date.toString().split('-');
      var data = new Date(year, month - 1, 1);
      var timestamp = data.getTime();
      return timestamp;
    },
    clickButtonFilter() {
      this.partialFilter = cloneDeep(this.filter);
      this.openMenu = true;
    },
    closeFromX() {
      this.filter = this.partialFilter;
      this.openMenu = false;

      if (this.applyClicked === false) {
        this.resetFiltersAndParams();
      }
      this.checkSelectedFilters();
    },
    checkSelectedFilters() {
      this.totalSelectedFilters = 0;
      if (this.filter.group) {
        this.totalSelectedFilters++;
      }
      if (this.filter.situation) {
        this.totalSelectedFilters++;
      }
      if (this.filter.start.length > 0) {
        this.totalSelectedFilters++;
      }
      if (this.filter.end.length > 0) {
        this.totalSelectedFilters++;
      }
      if (this.filter.updatedAt.length > 0) {
        this.totalSelectedFilters++;
      }
      if (this.filter.updateBy) {
        this.totalSelectedFilters++;
      }
    },
    removeSituation() {
      this.showChipSituation = false;
      this.filter.situation = null;
      this.params.situation = null;
      this.totalSelectedFilters--;
      this.fetch();
    },
    removeChipPeriodStart() {
      this.showChipPeriodStart = false;
      this.filter.start = [];
      this.params.start = [];
      this.totalSelectedFilters--;
      this.fetch();
    },
    removeChipPeriodEnd() {
      this.showChipPeriodEnd = false;
      this.filter.end = [];
      this.params.end = [];
      this.totalSelectedFilters--;
      this.fetch();
    },
    removeChipUpdatedAt() {
      this.showChipUpdatedAt = false;
      this.filter.updatedAt = [];
      this.params.updatedAt = [];
      this.totalSelectedFilters--;
      this.fetch();
    },
    removeUpdateBy() {
      this.showChipUpdateBy = false;
      this.filter.updateBy = null;
      this.params.user = null;
      this.totalSelectedFilters--;
      this.fetch();
    },
    callFilter() {
      this.applyClicked = true;
      this.openMenu = false;
      this.checkSelectedFilters();
      this.fetch();
    },
    showChips() {
      this.showChipSituation = this.filter.situation ? true : false;
      this.showChipPeriodStart = this.filter.start.length == 2 ? true : false;
      this.showChipPeriodEnd = this.filter.end.length == 2 ? true : false;
      this.showChipUpdatedAt = this.filter.updatedAt.length == 2 || this.filter.updatedAt.length == 1 ? true : false;
      this.showChipUpdateBy = this.filter.updateBy ? true : false;
    },
    async limparFiltro() {
      this.openMenu = true;
      this.applyClicked = false;
      this.resetFiltersAndParams();
      this.checkSelectedFilters();
      this.fetch();
    },

    resetFiltersAndParams() {
      this.filter = {
        group: '',
        situation: '',
        start: [],
        end: [],
        updatedAt: [],
        updateBy: '',
      };
      this.params = {
        group: null,
        situation: null,
        start: null,
        end: null,
        updatedAt: null,
        user: null,
      };
    },

    async exportar() {
      let requestData = this.paramsToRequest(this.params);
      let response = await eSocialService.rubricAutomationConfig.incidenceIRRFExport(requestData);
      if (response.status == 202) {
        this.showToast(
          "<div class='row'><div class='col-8 ml-0 mr-0 pl-0 pr-0 ' style='text-align: left;'>Sua solicitação de exportação foi recebida com sucesso! </br> Faça o download assim que o arquivo estiver disponível no menu 'Analytics' na opção 'Downloads' </div> <div class='col-4 ml-0 mr-0 pt-5 mt-4 pl-0 pr-0'><a href='/documents/download' style='text-decoration:none;'>Clique aqui para acessar</a></div></div>",
          'success',
          78,
        );
      }
    },
    capitalize(str) {
      if (str) {
        let withoutUnderline = str.split('_').join(' ').toLowerCase();
        return withoutUnderline[0].toUpperCase() + withoutUnderline.slice(1);
      }
    },
    concatDate(dateArray, label) {
      let chipTxt = '';
      if (dateArray.length === 1) {
        chipTxt = `${label}${formatDate(dateArray[0])}`;
      } else {
        const startDate = dateArray[0];
        const endDate = dateArray[1];
        const startMonthYear = startDate.split('-');
        const endMonthYear = endDate.split('-');
        chipTxt = `${label} ${startMonthYear[1]}/${startMonthYear[0]} a ${endMonthYear[1]}/${endMonthYear[0]}`;
      }
      return chipTxt;
    },
    formatDateMMYYYY(date) {
      if (!date) return null;
      const [year, month, day] = date.toString().split('-');
      return `${month}/${year}`;
    },
    start_and_end(str, lengthStr) {
      if (str != undefined) {
        if (str.length > lengthStr) {
          return str.substr(0, lengthStr) + '...';
        }
        return str;
      }
    },
    updateScrollers() {
      const dummy = this.$el.querySelector('#dummy');
      const _b = this.$el.querySelector('.v-data-table__wrapper');
      dummy.style.width = _b.scrollWidth + 'px';
    },
    setScrollersEventListener() {
      window.addEventListener('resize', this.updateScrollers);
      const linkScroller = (a, b) => {
        a.addEventListener('scroll', (e) => {
          b.scrollLeft = e.target.scrollLeft;
        });
      };
      const _a = this.$el.querySelector(`#scrollDuplicate`);
      const _b = this.$el.querySelector('.v-data-table__wrapper');
      linkScroller(_a, _b);
      linkScroller(_b, _a);
    },
    onTableInput() {
      //waits the page finishes loading data on the table DOM and updates the scroll width
      setTimeout(() => {
        this.updateScrollers();
      }, 250);
    },
    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },
    onInputSearchBar() {
      let pagination = this.incidenceIrrfPagination;
      pagination.page = 1;
      this.setIncidenceIrrfPagination(pagination);
      this.fetch();
    },
  },
};
</script>
<style scoped lang="scss">
@import './src/design/components/table.scss';

.incidence_irrf_filters {
  @import './src/design/components/input.scss';
  @import './src/design/components/select.scss';

  .combobox_incidence_irrf_filter {
    ::v-deep {
      .v-input__append-inner {
        margin-top: 5px !important;
      }
      .v-input__slot {
        .v-text-field__slot {
          input {
            font-weight: $font-weight-regular;
          }
        }
      }

      .v-select__slot {
        input {
          font-weight: $font-weight-regular;
          color: $brand-color-primary-medium;
        }
      }
    }
  }
}

::v-deep {
  .v-list-item {
    min-height: auto;
    .v-list-item__content {
      &.combobox_incidence_irrf_filter {
        padding: 16px 0;
        margin-left: 0;
        .v-list-item__title {
          line-height: 24px;
          font-size: $font-size-xs;
          color: $neutral-color-high-dark;
          font-weight: $font-weight-regular;
        }
      }
    }

    &.v-list-item--active {
      .v-list-item__content {
        &.combobox_incidence_irrf_filter_item_list {
          .v-list-item__title {
            color: $brand-color-primary-medium;
          }
        }
      }
    }
  }
}

.title {
  margin-top: 32px;

  height: 43px;
  width: 791px;
  left: 0px;
  top: 0px;
  font-family: 'Roboto', sans-serif;
  font-size: 32px !important;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--v-primary-base);
}
.subtitle {
  height: 24px;
  width: 750px;
  left: 0px;
  top: 51px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #575767;
  float: left;
  margin-bottom: 32px !important;
}
.subtitle-link {
  float: right;
  color: #575767;
  margin-bottom: 32px !important;
}
.subtitle-link a {
  text-decoration: none;
}
.icon-home {
  color: #2d5796 !important;
  font-size: 21px;
}
.text-link {
  text-decoration: none;
  color: #8d8d99 !important;
  font-weight: 400 !important;
  letter-spacing: 0.01em !important;
  font-size: 14px !important;
}

::v-deep .v-select__slot .v-select__selections .v-select__selection {
  font-family: Roboto !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #8686a2 !important;
}

.clear-filter-disabled {
  width: 352px;
  max-height: 48px !important;
  min-height: 48px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  background-color: #e7e7fa !important;
  color: #8686a2;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #e7e7fa !important;
  color: #8686a2 !important;
}
.clear-filter-enabled {
  width: 352px;
  max-height: 48px !important;
  min-height: 48px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  border: 1px solid var(--v-primary-base);
  background-color: #fff !important;
  color: var(--v-primary-base) !important;
}
#tables-container .v-card {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.v-menu__content {
  overflow-y: hidden !important;
  height: 600px !important;
}
::v-deep .v-data-table tr th.text-center {
  border-bottom: 0px !important;
  padding-right: 0px !important;
}
::v-deep .v-data-table tr th.text-start {
  border-bottom: 0px !important;
}
::v-deep .v-data-table tr td.text-center {
  padding-right: 0px !important;
}
::v-deep .v-data-table-header tr th.text-center span {
  color: var(--v-primary-base);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  cursor: default;
}
::v-deep #tables-container .v-card {
  box-shadow: 0px 0px !important;
}
::v-deep #tables-container {
  border-radius: 4px !important;
}
.v-application .elevation-1 {
  box-shadow: 0px 0px !important;
}
</style>
